import React, { useRef } from "react"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
import { navigate } from "gatsby"
import { InPageNav } from "../../components/navbar.js"
import { McatHero } from "../../components/sections/heroSection.js"
import MCATPricing from "../../components/sections/pricingSection.js"
import Showcase from "../../components/sections/showcaseSection.js"
import {
  ValuePropLeft,
  ValuePropRight,
  ValuePropsX3,
} from "../../components/sections/valuePropSection.js"

// import {CtaCard} from '../../components/sections/sections.js';

//SVGs
import VideoSvg from "../../images/video_1.svg"
import QuestionSvg from "../../images/questions.svg"
import WorkSvg from "../../images/work.svg"
import Savings from "../../images/savings.svg"
import Unparalleled from "../../images/unparalleled.svg"
import Guarantee from "../../images/finish.svg"
import CareerSVG from "../../images/career_progress.svg"

const card1 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "4+ Hour Days",
    "505+ Score Guarantee",
    "Be Fully Prepared In 4-6 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}

const card2 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "8+ Hour Days",
    "510+ Score Guarantee",
    "Be Fully Prepared In 3-4 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}
const card3 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "12+ Hour Days",
    "515+ Score Guarantee",
    "Be Fully Prepared In 1-2 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}

const Consulting = ({ location }) => {
  const mediaRef = useRef(null)
  const RedirectToPage = dest => {
    navigate(dest)
  }

  return (
    <Layout>
      <SEO title="99point9 Career Planning" />
      <InPageNav
        currentPath={location.pathname}
        pageTitle="Career Planning"
        navItems={[
          { link: "/career_planning", label: "Overview" },
          { link: "/career_planning/how_it_works", label: "How It Works" },
          { link: "/career_planning/schedule", label: "Class Schedule" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="Career Planning"
        body="The student-centric approach to student careers"
        ctaPrimary={{
          label: "Buy Course",
          redirect: "/career_planning/schedule/",
        }}
        ctaSecondary={{ label: "Request Demo", redirect: "/contact" }}
        img={CareerSVG}
        imgWidth="450px"
        backgroundColor="inherit"
      />
      <Showcase />
      <MCATPricing card1={card1} card2={card2} card3={card3} />
      <ValuePropsX3
        // caption = {"FOCUS ON YOUR WORK"}
        // refs={mcatBenefits}
        float={"center"}
        title={"What do you get?"}
        asides={[
          { title: "515+ Score Guarantee", src: Guarantee, alt: "Notes Image" },
          { title: "Affordable", src: Savings, alt: "Schedule Image" },
          {
            title: "Unparalleled Content",
            src: Unparalleled,
            alt: "Review Sheet Image",
          },
          { title: "92+ Hours Video", src: VideoSvg, alt: "Notes Image" },
          {
            title: "Online & In-class Options",
            src: WorkSvg,
            alt: "Schedule Image",
          },
          {
            title: "7000+ Lecture Questions",
            src: QuestionSvg,
            alt: "Review Sheet Image",
          },
        ]}
      />
      <ValuePropLeft
        caption={"BUILT-IN"}
        title={"Accountability"}
        refs={mediaRef}
      />

      <ValuePropRight caption={"AUTOMATED"} title={"Effortless Planning"} />

      <ValuePropLeft
        caption={"ARTIFICALLY INTELLIGENT"}
        title={"Content Personalization"}
      />

      <ValuePropRight
        caption={"HOLISTIC"}
        title={"Med. School Application Aid"}
      />

      <ValuePropLeft
        caption={"ARTIFICALLY INTELLIGENT"}
        title={"Content Personalization"}
      />

      {/* <Testimonial
      // refs = {testimonialRef}
      /> */}
    </Layout>
  )
}

export default Consulting
