import React from "react"
import styled from "styled-components"
//sections
import { Section } from "./sections.js"

//Styled-Components
const ShowCase = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: auto;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  @media (max-width: 800px) {
    margin: 0px auto 100px;
    transform: scale(calc(100vw * 0.09));
    padding-right: 10px;
    padding-left: 10px;
  }
`
const Img = styled.img`
  padding-top: 20px;
  height: ${props => (props.height ? props.height : "70px")};
  width: auto;
  margin: auto;
`
// const sectionPaddingTop = `${116*1}px`;
const sectionPaddingBottom = `${116 / 2}px`
const ShowcaseSection = () => {
  const sources = [
    [
      "45px",
      "https://www.logolynx.com/images/logolynx/b2/b20d69ce60804e87b75913c10ac30b00.png",
      "Harvard Medical School",
    ],
    [
      "110px",
      "https://learnonline.ecampusontario.ca/App_Content/Institution/425/Logos/UTCrst_Stacked_655_1024x768.jpeg",
      "UofT",
    ],
    [
      "45px",
      "https://image4.owler.com/logo/mckinsey---company_owler_20200206_184641_large.png",
      "Mckinsey & Company",
    ],
    [
      "65px",
      "https://med.stanford.edu/identity/logo/_jcr_content/main/panel_builder/panel_0/panel_builder_0/panel_0/image.img.320.high.png/1401777248905.png",
      "Stanford Medical School",
    ],
    [
      "65px",
      "https://www.hbs.edu/marketing/images/stacked_1200px_130327.jpg",
      "Harvard Business School",
    ],
  ]
  return (
    <Section style={{ paddingTop: sectionPaddingBottom }} color="dark">
      <ShowCase>
        {sources.map((item, index) => (
          <Img
            height={item[0]}
            src={item[1]}
            alt={item[2]}
            key={`${item[2] + index}`}
            width={"auto"}
          />
        ))}
      </ShowCase>
    </Section>
  )
}
export default ShowcaseSection
